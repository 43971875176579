import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(VueGtag, {
  config: {
    id: 'G-7H442KQ3FE'
  },
  appName: 'rentass_site',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(VueGtm, {
  id: 'GTM-WHD4TM25', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
})

Vue.use(VueCookies)
Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_URL
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
