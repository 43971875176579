<template>
  <v-app>
    <v-app-bar app color='#ffffff' height='70'>
      <v-row>
        <v-col cols='12' v-for="(item, index) in headerImg" :key="`header-img-${index}`">
          <a :href="item.url" @click="$router.push(item.url)">
            <v-img  width='150' contain height='50' :src='item.image'></v-img>
          </a>
        </v-col>
      </v-row>
      <template v-if='$vuetify.breakpoint.lgAndUp'>
        <template v-for='(item, index) in header'>
          <div :key='index'>
            <v-btn text large color='black' class='no-text-transform'
              v-if="!['Industries', 'Help'].includes(item.title)" @click='$router.push(item.content_url)'>{{ item.title }}</v-btn>
              <v-menu offset-y v-else>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn color='#000000' dark text class='black--text no-text-transform' v-bind='attrs' v-on='on'>{{ item.title }} <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card v-if="item.title === 'Industries'" :height="$vuetify.breakpoint.lgOnly ? 500 : ''"
                  width='1000' max-height="450" tile
                  class='pa-0 d-flex justify-space-between mt-5' style='overflow: hidden !important;'>
                  <v-container class='ml-16'>
                    <v-row>
                      <v-col cols='4'>
                        <v-list>
                          <v-list-item class='pl-0  scaling-element move-text' @mouseover='getItem(subItem)'
                            v-for='(subItem, index) in item.industry_list'
                            :key='index' @click='$router.push(subItem.industry_link)'
                          >
                          <v-list-item-title  class="ml-2">{{ subItem.title }}</v-list-item-title>
                        </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col class='pa-0' cols='8' v-if='subItemImage.industry_image'>
                        <v-expand-transition>
                          <div class='image-container'>
                            <!-- <img class='apply-transition img-gradient-overlay-industries'
                            :src='require(`../src/assets/images/carousel/img-6.png`)'
                            alt='image' width='500' height='600'> -->
                            <img class='apply-transition img-gradient-overlay-industries'
                              :src='subItemImage.industry_image'
                              alt='image' width='500' height='450'>
                              <div class='add-text-over text-h5 font-weight-bold pa-6'>{{ subItemImage.industry_description }}</div>
                          </div>
                        </v-expand-transition>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <template v-else>
                <v-list class="mt-5">
                  <v-list-item class='pl-8 scaling-element move-text-help'
                    v-for='(subItem, index) in item.help_list'
                    :key='index'  @click='$router.push(subItem.helplist_url)'
                  >
                  <v-list-item-title>{{ subItem.helplist_title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
                </template>
              </v-menu>
          </div>
        </template>
        <!-- <v-btn class='rounded-pill text-body-1 text-lowercase white--text outlined-btn' color='#000000' outlined @click="$router.push('/pricing')">Start trail
        </v-btn> -->
        <v-btn class='rounded-pill font-weight-medium no-text-transform ml-4 gradient-text' @click="$router.push('/demo')"> Book a demo</v-btn>
      </template>
      <template v-if='$vuetify.breakpoint.mdAndDown'>
      <v-spacer></v-spacer>
      <v-btn icon color="0000000"  @click.stop='openDrawer()'><v-icon>mdi-menu </v-icon></v-btn>
      </template>
    </v-app-bar>
    <template v-if='$vuetify.breakpoint.mdAndDown'>
      <v-navigation-drawer v-model="openNavBar" app color='#35313A' temporary width="100%"  height="100%" right>
        <v-icon size="20" class="ml-3 mt-3" color="#ffffff" @click="closeDrawer()">mdi-arrow-left</v-icon>
          <v-container>
            <v-list :key="tableKey">
              <v-list-item-group
                v-model="selectedItem"
                color="#ffffff"
              >
              <template v-for='(item, index) in header'>
                <v-list-item :key='index' v-if="!['Industries', 'Help'].includes(item.title)"  @click='$router.push(item.content_url)'>
                  <v-list-item-title class="font-weight-bold textcolor-apply">{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-group v-else :key='index' :value="true">
                  <template v-slot:activator>
                    <v-list-item-title class="font-weight-bold textcolor-apply">{{ item.title }}</v-list-item-title>
                  </template>
                  <template v-if="item.title === 'Industries'">
                    <v-list-item v-for='(subItem, industryIndex) in item.industry_list'
                      @click='$router.push(subItem.industry_link)' :key="industryIndex">
                    <v-list-item-title class="ml-6 font-weight-bold textcolor-apply">{{subItem.title}}</v-list-item-title>
                  </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item v-for='(subItem, helpIndex) in item.help_list' @click='$router.push(subItem.helplist_url)' :key="helpIndex">
                    <v-list-item-title class="ml-6 font-weight-bold textcolor-apply">{{subItem.title}}</v-list-item-title>
                  </v-list-item>
                  </template>
                </v-list-group>
              </template>
              </v-list-item-group>
            </v-list>
          </v-container>
        <v-container>
        <v-btn class='rounded-pill font-weight-medium no-text-transform
          ml-4 gradient-text' @click="$router.push('/demo')"> Book a demo</v-btn>
        </v-container>
      </v-navigation-drawer>
    </template>
    <v-main>
     <router-view/>
    </v-main>
    <v-footer color='primary lighten-1' padless v-if="isFooter">
      <v-container>
        <v-row justify='center' :style="$vuetify.breakpoint.mdAndDown ? { display: 'flex', flexDirection: 'column',  justifyContent: 'center' } : {}" no-gutters>
          <v-col class="pa-0 "  xs='12' sm='12' xl='2' lg='2' md='12' v-for="(item, index) in  footerObj.footer" :key="index">
            <v-card flat color='primary lighten-1'>
              <v-img width='150' contain class='mt-4' height='50' :src='item.image'></v-img>
               <!-- <v-img width='150' class='mt-4' height='50' src='../src/assets/images/logo.svg'></v-img> -->
              <v-card-text>
                <span class='white--text'>{{ item.description }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0" xs='12' sm='12' xl='2' lg='2' md='12'>
            <v-card flat tile color='primary lighten-1'>
              <v-card-title class='font-weight-bold white--text text-decoration-underline'>{{ footerObj.Industry_list.title }}</v-card-title>
                <v-card-text class='pa-2 ml-3' v-for=' (subItem, subIndex) in footerObj.Industry_list.url' :key='subIndex'>
                <div class='white--text pointer scaling-element' @click="$router.push(subItem.url)">{{ subItem.title }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0 " xs='12' sm='12' xl='1' lg='1' md='12'>
            <v-card flat tile color='primary lighten-1'>
              <v-card-title class='font-weight-bold white--text text-decoration-underline px-lg-1'>{{ footerObj.solution.title }}</v-card-title>
                <v-card-text class='pa-2' :class="$vuetify.breakpoint.mdAndDown ? 'ml-3' :''" v-for=' (subItem, subIndex) in footerObj.solution.url' :key='subIndex'>
                <div class='white--text pointer scaling-element'  @click="$router.push(subItem.url)">{{ subItem.title }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0" xs='12' sm='12' xl='1' lg='1' md='12'>
            <v-card flat tile color='primary lighten-1'>
              <v-card-title class='font-weight-bold white--text text-decoration-underline px-lg-1 ml-lg-3'>{{ footerObj.support.title }}</v-card-title>
                <v-card-text class='pa-2 ml-3' v-for='(subItem, subIndex) in footerObj.support.url' :key='subIndex'>
                <div class='white--text pointer scaling-element' @click="$router.push(subItem.url)">{{ subItem.title }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0" xs='12' sm='12' xl='3' lg='2' md='12' v-for='(item, subIndex) in footerObj.contact_us'
            :key='`contact-us${subIndex}`'>
            <v-card flat tile color='primary lighten-1'>
              <v-card-title class='font-weight-bold white--text text-decoration-underline' :class="$vuetify.breakpoint.lgAndUp ? 'ml-9' : ''">{{ item.title }}</v-card-title>
                <!-- <v-card-text class='pa-2 ml-3'>
                  <v-row>
                    <v-col cols="2" class="d-flex align-center"><v-icon color="white">mdi-map-marker</v-icon></v-col>
                    <v-col cols="10"><div class='white--text  scaling-element '>{{ item.address }} </div></v-col>
                  </v-row>
                   <v-row>
                    <v-col cols="2" class="d-flex align-center pointer scaling-element"><v-icon class="pointer" color="white">mdi-phone</v-icon></v-col>
                    <v-col cols="10"><div class='white--text pointer scaling-element'>{{ item.primary_phone }} </div>
                    <div class='white--text pointer scaling-element '>{{ item.secondary_phone }}</div>
                    </v-col>
                  </v-row>
                  <v-row @click="openMail(item.email)">
                    <v-col cols="2" class="d-flex align-center"><v-icon color="white" class="pointer scaling-element">mdi-email</v-icon></v-col>
                    <v-col cols="10"><div class='white--text pointer scaling-element '>{{ item.email }} </div></v-col>
                  </v-row>
                </v-card-text> -->
                 <v-card-text class="pa-0" v-if="$vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly || $vuetify.breakpoint.xsOnly">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar :color="item.color">
                      <v-icon size="25" color="#ffffff" >mdi-map-marker</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class='white--text  scaling-element'>
                           {{ item.address }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-avatar class="pa-0" :color="item.color">
                      <v-icon size="25" class="pointer" color="#ffffff" >mdi-phone</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class='white--text  scaling-element'>
                      {{ item.primary_phone }}
                      <div>{{ item.secondary_phone }} </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0" @click="openMail(item.email)">
                    <v-list-item-avatar :color="item.color">
                      <v-icon size="25" class="pointer" color="#ffffff" >mdi-email</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class='white--text  scaling-element'>
                        {{ item.email }}
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-text v-else>
                 <v-row>
                    <v-col cols="1" class="d-flex align-center pr-0"><v-icon color="white">mdi-map-marker</v-icon></v-col>
                    <v-col cols="11" class="pl-0"><div class='white--text  scaling-element '>{{ item.address }}ddd </div></v-col>
                 </v-row>
                  <v-row>
                    <v-col cols="1" class="d-flex align-center pointer scaling-element pr-0"><v-icon class="pointer" color="white">mdi-phone</v-icon></v-col>
                    <v-col cols="11" class="pl-0"><div class='white--text pointer scaling-element'>{{ item.primary_phone }} </div>
                    <div class='white--text pointer scaling-element '>{{ item.secondary_phone }}</div>
                    </v-col>
                  </v-row>
                  <v-row @click="openMail(item.email)">
                    <v-col cols="1" class="d-flex align-center pr-0"><v-icon color="white" class="pointer scaling-element">mdi-email</v-icon></v-col>
                    <v-col cols="11" class="pl-0"><div class='white--text pointer scaling-element '>{{ item.email }}fs </div></v-col>
                  </v-row>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col xl="3" lg="4" md="6">
            <iframe height="350" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3895.022055440991!2d77.8384494!3d12.763961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae76d21644eed7%3A0x6cb5ac06994b0d58!2sHosur%20IT%20Park!5e0!3m2!1sen!2sin!4v1685616891741!5m2!1sen!2sin" width="90%"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="$vuetify.breakpoint.lgAndUp">
        <v-row class="white--text"><v-divider class="custom-divider"></v-divider></v-row>
      </v-container>
      <v-container>
        <v-row class='d-flex justify-left' :style="$vuetify.breakpoint.mdAndDown ? { display: 'flex', flexDirection: 'column',  justifyContent: 'center' } : {}">
          <v-divider  v-if="$vuetify.breakpoint.mdAndDown" class="custom-divider"></v-divider>
          <v-col xs='12' sm='12' xl='12' lg='12' md='12'>
            <div class="mx-1 font-weight-bold white--text text-subtitle-1" style="display:inline-block !important"
            v-if="$vuetify.breakpoint.mdAndUp">
             © RentAss - A product of <a href="https://www.processdrive.com/" style="color:gold" target="_blank" class="underline-on-hover">
            ProcessDrive India Private Limited
              </a>
           </div>
           <template v-else>
            <small class="font-weight-bold white--text">
              © RentAss - A product of <a href="https://www.processdrive.com/" style="color:gold" target="_blank" class="underline-on-hover">
              ProcessDrive India Private Limited
              </a>
            </small>
           </template>
          </v-col>
        <!-- <v-row class='d-flex justify-right'> -->
          <!-- <v-col xs='4'  sm='12' xl='2' lg='2' md='6' class=" sample d-flex justify-space-around ml-auto d-flex justify-right">
            <template v-for='(item, linkIndex) in footerObj.social_media.url'>
              <div :key="linkIndex">
                <v-btn icon color="#ffffff" :href="item.link" target="_blank" class="pa-1">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </div>
            </template>
          </v-col> -->
          <v-col xs='12' sm='4' xl='3' lg='2' md='6' class="ml-2 pb-10">
            <v-row>
            <template v-for='(item, linkIndex) in footerObj.social_media.url'>
              <div :key="linkIndex">
                <v-btn icon color="#ffffff" :href="item.link"  target="_blank" class="pa-1">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </div>
            </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-card width="350" color="#212121" v-if="showCookiePolicy" class="cookie-policy ma-4 rounded-xl">
      <v-card-title class="pa-0">
        <v-row justify="center">
        <v-avatar size="100">
        <v-icon color="#e8b015" size="80">mdi-cookie</v-icon>
        </v-avatar>
        </v-row>
      </v-card-title>
      <v-card-text>
        <span class="text-subtitle-2 font-weight-bold white--text">   We use cookies for improving user experience, analytics and marketing. </span>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="">
            <v-btn @click="acceptCookies" class="btn-color-apply rounded-lg" block>Accept</v-btn>
          </v-col>
          <v-col cols="6" class="">
            <v-btn @click="reject" block class="btn-color-apply rounded-lg">Reject</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import { api } from './../src/plugins/axios'
export default {
  data () {
    return {
      drawer: false,
      selectedItem: 0,
      tableKey: 0,
      group: null,
      links: [
        'Home',
        'About Us',
        'Contact Us'
      ],
      items: [
        { title: 'User Guide', link: 'user-guide' },
        { title: 'FAQ', link: '/FAQ' }
      ],
      imageText: 'RentAss is a digitalize Equipment Rental software developed by ProcessDrive India.',
      subItemImage: {},
      showCookiePolicy: true,
      openNavBar: false,
      footerObj: {},
      header: [],
      socialMediaIcons: ['mdi-youtube', 'mdi-instagram', 'mdi-facebook', 'mdi-linkedin', 'mdi-whatsapp'],
      headerImg: [],
      isFooter: false
    }
  },
  created () {
    if (this.$cookies.get('cookiePolicyAccepted')) {
      this.showCookiePolicy = false
    }
  },
  mounted () {
    this.geHeader()
    this.getFooter()
    this.geHeaderImage()
    this.$root.$on('hideDrawer', () => {
      // this.drawer = false
      this.openNavBar = false
    })
  },
  methods: {
    getItem (item) {
      this.subItemImage = item
    },
    geHeader () {
      api.get('/headers')
        .then((response) => {
          this.header = response.data
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    getFooter () {
      api.get('/footer')
        .then((response) => {
          this.isFooter = true
          const getFooter = response.data
          getFooter.social_media.url.forEach((element, index) => {
            element.icon = this.socialMediaIcons[index]
          })
          this.footerObj = getFooter
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    geHeaderImage () {
      api.get('/header_image')
        .then((response) => {
          this.headerImg = response.data
        }).catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    openMail (email) {
      window.location.href = `mailto:${email}?subject=&body=`
    },
    openDrawer () {
      this.openNavBar = true
      this.drawer = !this.drawer
      this.tableKey++
    },
    closeDrawer () {
      this.openNavBar = false
    },
    acceptCookies () {
      // Set a cookie indicating the user has accepted the cookie policy
      this.$cookies.set('cookiePolicyAccepted', true)
      this.showCookiePolicy = false // Hide the cookie policy component
    },
    reject () {
      this.showCookiePolicy = false
    }
  }
}
</script>
<style lang='scss'>
@import './assets/css/app.scss';
.gradient-text {
      background: linear-gradient(to right, #FFA726, #FFDAA4);
      color: #000000;
    }
.outlined-btn {
  border: 1px solid #FFA726; /* Adjust the border color as needed */
}

.custom-list-item::before {
  /* Styles for the normal state */
  content: 'ddd' !important;
  display: block !important;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0 !important;
  opacity: 0;
  transition: opacity 0.3s !important;
}

.custom-list-item:hover::before {
  /* Styles for the hover state */
  background-color: #bdbdbd !important;
}
.apply-div:hover {
  width: 4px; /* Set the width and height to make a square */
  height: 40px;
  background: linear-gradient(to right, #FFA726, #FFDAA4);
 /* Set border-radius to 50% for a circular shape */
}

.v-card--reveal {
align-items: center;
bottom: 0;
justify-content: center;
opacity: .5;
position: absolute;
width: 100%;
}
.apply-transition {
      transition: transform 1s ease-in-out !important; /* Adjust duration and timing function */
      object-fit: cover !important;
      width: 100% !important;
}
.add-text-over {
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom as needed */
  left: 10px; /* Adjust the distance from the left as needed */
  color: white; /* Adjust the text color as needed */
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: bold; /* Adjust the font weight as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
  .image-container {
  position: relative !important;
}
.move-text:hover {
  transform: translateX(15px) !important;
   transition: transform 0.5s ease-in-out !important;
  //  background: #bdbdbd !important;
  background: linear-gradient(to right, #FFA726, #FFDAA4)
}
.move-text-help:hover {
  transform: translateX(4px) !important;
  transition: transform 0.7s ease-in-out !important;
  //  background: #bdbdbd !important;
  background: linear-gradient(to right, #FFA726, #FFDAA4)
}
.pointer {cursor: pointer !important;}
  .scaling-element {
      transition: transform 0.4s ease-in-out; /* Adjust duration and timing function */
    }
 .scaling-element:hover {
      transform: scale(1.1); /* Scale up by 20% on hover */
    }
.underline-on-hover {
  text-decoration: underline;
  color: #FFA726;
  cursor: pointer;
  transition: text-decoration 0.3s;
}

.underline-on-hover:hover {
  text-decoration: none;
}

.custom-divider {
  background-color: white; /* Set your desired color, e.g., gold */
}
.textcolor-apply {
 color:#ffffff !important
 }
 .cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  padding: 10px;
  text-align: center;
}
.btn-color-apply {
  background: #e8b015 !important;
}
</style>
