import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 23 to 26 - To not show the navigation duplicated issue in console
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'RentAss - Affordable Rental Management Software | Best Rental Software In India | Billing Software | Saas Development',
      metaTags: [
        {
          name: 'description',
          content: 'Achieve Maximize profits with our rental software. Manage medical devices, scaffolding, cameras, bridal jewelry, costumes and heavy machinery. Track rentals, identify underused assets, and enhance decision-making with detailed reports.'
        },
        {
          property: 'og:description',
          content: 'Achieve Maximize profits with our rental software. Manage medical devices, scaffolding, cameras, bridal jewelry, costumes and heavy machinery. Track rentals, identify underused assets, and enhance decision-making with detailed reports.'
        }
      ]
    }
  },
  {
    path: '/Whoweare',
    name: 'who-we-are',
    component: () => import('@/components/whoWeAre/WhoWeAre.vue'),
    meta: {
      title: 'RentAss - Quality and Excellence | Customer-Centric | Values-Oriented',
      metaTags: [
        {
          name: 'description',
          content: 'ProcessDrive is a forward-thinking tech company specializing in digital transformation. We leverage next-generation intelligence to help businesses target opportunities and achieve impactful change.'
        },
        {
          property: 'og:description',
          content: 'ProcessDrive is a forward-thinking tech company specializing in digital transformation. We leverage next-generation intelligence to help businesses target opportunities and achieve impactful change.'
        }
      ]
    }
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/components/Feature/Feature.vue'),
    meta: {
      title: 'RentAss - features | Inventory Management | Requirement Analysis | Rporting And Analytics',
      metaTags: [
        {
          name: 'description',
          content: 'Efficiently manage equipment rentals with asset management, billing, invoicing, and detailed reporting. Enjoy multi-location support, customization, and a user-friendly interface for medical devices, cameras, bridal jewelry, costumes, and more.'
        },
        {
          property: 'og:description',
          content: 'Efficiently manage equipment rentals with asset management, billing, invoicing, and detailed reporting. Enjoy multi-location support, customization, and a user-friendly interface for medical devices, cameras, bridal jewelry, costumes, and more.'
        }
      ]
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/components/Pricing/Pricing.vue'),
    meta: {
      title: 'RentAss - pricing | Annual Subscription | Basic | Advanced | Ultimate',
      metaTags: [
        {
          name: 'description',
          content: 'Explore competitive, affortable pricing for Coustume rental, medical devices, scaffolding, cameras, bridal jewelry, and power tools. Flexible options for single or multi-location needs'
        },
        {
          property: 'og:description',
          content: 'Explore competitive, affortable pricing for Coustume rental, medical devices, scaffolding, cameras, bridal jewelry, and power tools. Flexible options for single or multi-location needs'
        }
      ]
    }
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('@/components/Demo/Demo.vue'),
    meta: {
      title: 'RentAss - Free Trial | Demo Request | Contact Us',
      metaTags: [
        {
          name: 'description',
          content: 'Try our Equipment Rental Software with a free demo and trial. Experience all features risk-free sign up today.'
        },
        {
          property: 'og:description',
          content: 'Try our Equipment Rental Software with a free demo and trial. Experience all features risk-free sign up today.'
        }
      ]
    }
  },
  {
    path: '/faqs',
    name: 'FAQ',
    component: () => import('@/components/FAQ/FAQ.vue'),
    meta: {
      title: 'RentAss - Customer Support | Live Chat | Knowledge Base',
      metaTags: [
        {
          name: 'description',
          content: 'Find answers to common questions about our Equipment Rental Software, covering setup, inventory management, and support. For more help, contact our team.'
        },
        {
          property: 'og:description',
          content: 'Find answers to common questions about our Equipment Rental Software, covering setup, inventory management, and support. For more help, contact our team.'
        }
      ]
    }
  },
  {
    path: '/user-guide',
    name: 'User guide',
    component: () => import('@/components/UserGuide/UserGuide.vue'),
    meta: {
      title: 'RentAss - Mobile Management | Web Application',
      metaTags: [
        {
          name: 'description',
          content: 'RentAss guide covers essential features including setup, inventory, customer management, invoicing, reporting, and user permissions. For help, contact our team.'
        },
        {
          property: 'og:description',
          content: 'RentAss guide covers essential features including setup, inventory, customer management, invoicing, reporting, and user permissions. For help, contact our team.'
        }
      ]
    }
  },
  {
    path: '/scaffolding-centring-materials-rentals',
    name: 'Scaffolding equipment',
    component: () => import('@/components/Industries/ScaffoldingEquipment'),
    meta: {
      title: 'RentAss - Scaffolding Hire Services in India | Centring Rental | Construction Equipment Rental',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our comprehensive range of scaffolding and centring equipment, including adjustable scaffolding frames, sturdy platforms, and precision centring props. Enhance safety and efficiency on construction sites with top-quality solutions'
        },
        {
          property: 'og:description',
          content: 'Explore our comprehensive range of scaffolding and centring equipment, including adjustable scaffolding frames, sturdy platforms, and precision centring props. Enhance safety and efficiency on construction sites with top-quality solutions'
        }
      ]
    }
  },
  {
    path: '/heavy-equipment-rentals',
    name: 'Heavy equipment',
    component: () => import('@/components/Industries/HeavyEquipment.vue'),
    meta: {
      title: 'RentAss - Rental Services | Heavy Equipment Rental Services | Affordable Rates',
      metaTags: [
        {
          name: 'description',
          content: 'Discover our comprehensive heavy equipment rental services, offering bulldozer, excavator, crane, loader, and other construction and industrial equipment rentals.'
        },
        {
          property: 'og:description',
          content: 'Discover our comprehensive heavy equipment rental services, offering bulldozer, excavator, crane, loader, and other construction and industrial equipment rentals.'
        }
      ]
    }
  },
  {
    path: '/sound-system-rental',
    name: 'Sound System',
    component: () => import('@/components/Industries/AudiosEquipment.vue'),
    meta: {
      title: 'RentAss - Sound System Hire | High Quality Sound Sytem Rental',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our sound system rental services featuring Events Managements including speakers, microphones, mixers, and accessories.'
        },
        {
          property: 'og:description',
          content: 'Explore our sound system rental services featuring Events Managements including speakers, microphones, mixers, and accessories.'
        }
      ]
    }
  },
  {
    path: '/camera-rentals',
    name: 'camera accessories',
    component: () => import('@/components/Industries/CameraAccessories.vue'),
    meta: {
      title: 'RentAss - DSLRs | Camera Rental In Hosur | Discounts on cameras | Lens Rental',
      metaTags: [
        {
          name: 'description',
          content: 'Discover our camera rental services offering a wide range of equipment including DSLRs, mirrorless cameras, lenses, and accessories for all your photography and videography needs.'
        },
        {
          property: 'og:description',
          content: 'Discover our camera rental services offering a wide range of equipment including DSLRs, mirrorless cameras, lenses, and accessories for all your photography and videography needs.'
        }
      ]
    }
  },
  {
    path: '/costume-rentals',
    name: 'costume rental',
    component: () => import('@/components/Industries/CostumeRental.vue'),
    meta: {
      title: 'RentAss - Wedding Costume Rental | Kids Dress Rental Shop | Maternity Gowns To Rent | Kids Hire Costumes | gown for.kids',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our costume rental services offering a variety of options including kids costumes, wedding attire, and themed outfits for events and productions.'
        },
        {
          property: 'og:description',
          content: 'Explore our costume rental services offering a variety of options including kids costumes, wedding attire, and themed outfits for events and productions.'
        }
      ]
    }
  },
  {
    path: '/partyEquipment-supplies-rentals',
    name: 'party suppliers',
    component: () => import('@/components/Industries/PartySuppliers.vue'),
    meta: {
      title: 'RentAss - Rental Supply Hiring | Rental Supply Wholesale',
      metaTags: [
        {
          name: 'description',
          content: 'Discover our party suppliers rental services, providing everything from tables, chairs, and linens to decorations, lighting, and entertainment for your special event.'
        },
        {
          property: 'og:description',
          content: 'Discover our party suppliers rental services, providing everything from tables, chairs, and linens to decorations, lighting, and entertainment for your special event.'
        }
      ]
    }
  },
  {
    path: '/medical-equipment-rentals',
    name: 'medical',
    component: () => import('@/components/Industries/MedicalEquipment.vue'),
    meta: {
      title: 'RentAss - oxygen machine rental | Hospital Bed Rental | o2 Hire Cost | Hospital Wheelchair Price',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our range of medical equipment rentals, including oxygen concentrator, patient beds, patient wheel chair, surgical instruments, and rehabilitation tools. Ensure efficient healthcare delivery with reliable equipment solutions'
        },
        {
          property: 'og:description',
          content: 'Explore our range of medical equipment rentals, including oxygen concentrator, patient beds, patient wheel chair, surgical instruments, and rehabilitation tools. Ensure efficient healthcare delivery with reliable equipment solutions'
        }
      ]
    }
  },
  {
    path: '/bridal-jewellery-rentals',
    name: 'bridal jewellery',
    component: () => import('@/components/Industries/BridalJewellery.vue'),
    meta: {
      title: 'RentAss - Wedding Jewelry Rental | Bridal Set For Rent Near Me | Costume Jewelry Rental',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our bridal jewellery rental services, featuring exquisite collections of necklaces, earrings, bracelets, and accessories to enhance your special day.'
        },
        {
          property: 'og:description',
          content: 'Explore our bridal jewellery rental services, featuring exquisite collections of necklaces, earrings, bracelets, and accessories to enhance your special day.'
        }
      ]
    }
  },
  {
    path: '/power-equipment-rentals',
    name: 'power tools',
    component: () => import('@/components/Industries/PowerTools.vue'),
    meta: {
      title: 'RentAss - Industrial Generators For Hire | Generator Hire hss | Rental Drilling Machine',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our power tools rental services, offering a diverse selection of reliable equipment including drills, saws, sanders, and more for your diy event hire  or professional projects.'
        },
        {
          property: 'og:description',
          content: 'Explore our power tools rental services, offering a diverse selection of reliable equipment including drills, saws, sanders, and more for your diy event hire  or professional projects.'
        }
      ]
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/components/PrivacyPolicy/PrivacyPlicy.vue'),
    meta: {
      title: 'RentAss - Data Security | Compliance | ',
      metaTags: [
        {
          name: 'description',
          content: 'Our privacy policy outlines how RentAss collects, uses, and protects personal information, ensuring transparency and security for all users.'
        },
        {
          property: 'og:description',
          content: 'Our privacy policy outlines how RentAss collects, uses, and protects personal information, ensuring transparency and security for all users.'
        }
      ]
    }
  },
  {
    path: '/terms-and-condition',
    name: 'terms condition',
    component: () => import('@/components/TermsConditions/TermsConditions.vue'),
    meta: {
      title: 'RentAss - Account Security | Data Collection | License Grant',
      metaTags: [
        {
          name: 'description',
          content: 'Explore our Terms and Conditions, detailing guidelines for the use of RentAss services, ensuring clarity and fairness for all users.'
        },
        {
          property: 'og:description',
          content: 'Explore our Terms and Conditions, detailing guidelines for the use of RentAss services, ensuring clarity and fairness for all users.'
        }
      ]
    }
  },
  {
    path: '/technical-difficulties',
    name: 'Technical difficulties',
    component: () => import('@/components/TechnicalDifficulties.vue'),
    meta: {
      title: 'RentAss - Software Company | IT Services | ERP Solutions | Digital Transformation | SaaS Development',
      metaTags: [
        {
          name: 'description',
          content: 'Unlock scalable growth with digital solutions from ProcessDrive. Our expertise streamlines operations enhances efficiency and drives profitability. Partner with us for unparalleled success and empower your team to thrive in the digital age..'
        },
        {
          property: 'og:description',
          content: 'Unlock scalable growth with digital solutions from ProcessDrive. Our expertise streamlines operations enhances efficiency and drives profitability. Partner with us for unparalleled success and empower your team to thrive in the digital age..'
        }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Update the title based on the route meta
// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title || 'RentAss'
//   next()
// })
router.beforeEach((to, _, next) => {
  const meta = to.matched.slice().reverse().find(r => r.meta)?.meta || {}
  document.title = meta.title || ''
  document.head.querySelector('meta[name="description"]')?.setAttribute('content', meta.description || '') || document.head.appendChild(Object.assign(document.createElement('meta'), { name: 'description', content: meta.description || '' }))
  next()
})

export default router
